import React from 'react'
import { Link } from 'react-router-dom'
import './header.scss'
import therapist from 'assets/images/ladywithlaptop.png'
import manwithbeards from 'assets/images/manwithbeards.png'
import {
  Select,
  Option,
  selectClasses,
  extendTheme,
  CssVarsProvider,
} from '@mui/joy'

const chevron = (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M3 4.5L6 7.5L9 4.5' fill='#475467' />
    <path
      d='M3 4.5L6 7.5L9 4.5H3Z'
      stroke='#475467'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const theme = extendTheme({
  components: {
    JoySelect: {
      defaultProps: {
        indicator: chevron,
      },
    },
  },
})

const options = [
  {
    id: 1,
    option: 'Mental health coaching',
  },
  {
    id: 2,
    option: 'Career advice',
  },
  {
    id: 3,
    option: 'Relationship counselling',
  },
  {
    id: 4,
    option: 'Educational counselling',
  },
]

const optionStyles = {
  width: '100%',
  color: '#344054',
  fontFamily: 'DM Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
}

const Header = () => {
  return (
    <header className='header'>
      <div className='headerwrapper'>
        <div className='header-_inner__one'>
          <div className='headertext__container'>
            <p className='headertext'>
              Talk to a therapist or life coach{' '}
              <span className='coloredtext'>for free today</span>
            </p>
          </div>
          <div className='headertext__container__two'>
            <p className='headertext2'>
              Your metal health includes your emotional, psychological, and
              social well-being. It affects how we think, feel, and act.
            </p>
          </div>
          <div className='dropdown__therapist'>
            <div className='wheredropdown'>
              <CssVarsProvider theme={theme}>
                <Select
                  placeholder='Where do you seek care?'
                  sx={{
                    width: '100%',
                    color: '#344054',
                    fontFamily: 'DM Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '24px',
                    height: '100%',
                    [`& .${selectClasses.indicator}`]: {
                      transition: '0.2s',
                      [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                      },
                    },
                  }}
                >
                  {options.map(({ id, option }) => {
                    return (
                      <Option key={id} value={option} sx={optionStyles}>
                        {option}
                      </Option>
                    )
                  })}
                </Select>
              </CssVarsProvider>
            </div>
            <div className='findtherapist'>
              <Link to='/search-coach'>
                <p className='findtherapist__text'>Find therapist</p>
              </Link>
            </div>
          </div>
        </div>

        <div className='header-_inner__two'>
          <div className='vector'></div>
          <div className='image'>
            <span className='imagespanone'>
              <img src={therapist} alt='therapist' loading='lazy' />
            </span>
            <span className='imagespantwo'>
              <img src={manwithbeards} alt='therapist' loading='lazy' />
            </span>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
