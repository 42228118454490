import therapist from 'assets/images/AdekunleAbiona.jpeg'

export const therapistData = [
  {
    id: 1,
    therapistImage: therapist,
    name: 'Dr. Adekunle Abiona',
    title: 'Chief Medical Director, Spinex Global Resources',
  },
  //   {
  //     id: 2,
  //     image: <img src={therapist} alt='therapist' loading='lazy' />,
  //     name: 'Chioma Davids',
  //     title: 'Therapist, Career Coach',
  //   },
  //   {
  //     id: 3,
  //     image: <img src={therapist} alt='therapist' loading='lazy' />,
  //     name: 'Chioma Davids',
  //     title: 'Therapist, Career Coach',
  //   },
]
