import React, { useState, useEffect } from 'react'
import Footer from 'components/footer/footer'
import Navbar from 'components/nav/nav'
import { Link } from 'react-router-dom'
import { therapistData } from 'therapistData'
import { getBoxSize } from 'util'
import './search-coach.scss'
import {
  Select,
  Option,
  selectClasses,
  extendTheme,
  CssVarsProvider,
} from '@mui/joy'

const chevron = (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M3 4.5L6 7.5L9 4.5' fill='#475467' />
    <path
      d='M3 4.5L6 7.5L9 4.5H3Z'
      stroke='#475467'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const theme = extendTheme({
  components: {
    JoySelect: {
      defaultProps: {
        indicator: chevron,
      },
    },
  },
})

const options = [
  {
    id: 1,
    option: 'Mental health coaching',
  },
  {
    id: 2,
    option: 'Career advice',
  },
  {
    id: 3,
    option: 'Relationship counselling',
  },
  {
    id: 4,
    option: 'Educational counselling',
  },
]

const optionStyles = {
  width: '100%',
  color: '#344054',
  fontFamily: 'DM Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
}

const SearchCoach = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const window_width = 341.681
    const window_height = 433
    const params = {
      y_min_size: 200,
      x_min_size: window_width,
      x_max_size: window_width,
      y_max_size: window_height,
    }
    const { width, height } = getBoxSize(window_width, window_height, params)
    setSize({
      width,
      height,
    })
  }, [])

  return (
    <div className='searchContainer'>
      <Navbar />
      <section className='appointmentContainer'>
        {therapistData.map((user) => {
          return (
            <div key={user.id} className='scheduleAppointment'>
              <div className='therapistavi'>
                {/* <span className='rectangle'></span> */}
                <img
                  src={user.therapistImage}
                  alt={user.name}
                  width={size.width}
                  height={size.height}
                  className='therapistpics'
                />
              </div>
              <div className='therapistdetails'>
                <span className='therapistName'>{user.name}</span>
                <span className='therapistTitle'>{user.title}</span>
                <div className='divbtn'>
                  <a href='https://calendly.com/mentalhealthsolution22/30min'>
                    <button className='appointmentbtn'>
                      Schedule an appointment
                    </button>
                  </a>
                </div>
              </div>
            </div>
          )
        })}
      </section>
      <div className='footerdiv'>
        <Footer>
          {footerChildren.map(({ id, item }) => {
            return (
              <Link key={id} className='footer__btn'>
                <span className='about_span'>{item}</span>
              </Link>
            )
          })}
        </Footer>
      </div>
    </div>
  )
}

export default SearchCoach

const footerChildren = [
  {
    id: 1,
    item: 'Support',
  },
  {
    id: 2,
    item: 'Privacy policy',
  },
  {
    id: 3,
    item: 'Terms of service',
  },
]
