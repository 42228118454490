import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from 'components/footer/footer'
import Navbar from 'components/nav/nav'
import calendly from 'assets/images/calendly1.jpg'
import therapist from 'assets/images/AdekunleAbiona.jpeg'
import { getBoxSize } from 'util'
import './appointment.scss'

const AppointmentPage = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const window_width = 341.681
    const window_height = 433
    const params = {
      y_min_size: 200,
      x_min_size: window_width,
      x_max_size: window_width,
      y_max_size: window_height,
    }
    const { width, height } = getBoxSize(window_width, window_height, params)
    setSize({
      width,
      height,
    })
  }, [])
  return (
    <section className='appointmentsection'>
      <Navbar />
      <main className='Appointmentmain'>
        <div className='main__heading'>
          <span className='heading'>Book your appointment</span>
        </div>
        <div className='therapist__form'>
          <div className='therapistframe'>
            <div className='therapistwrapper'>
              <div className='group'>
                <span>
                  <img
                    className='groupImage'
                    src={therapist}
                    alt='therapist'
                    width={size.width}
                    height={size.height}
                  />
                </span>
              </div>
              <div className='name__details'>
                <span className='name'>Dr. Adekunle Abiona</span>
                <span className='title'>
                  Chief Medical Director, Spinex Global Resources
                </span>
              </div>
              <span className='desc'>
                Dr. Adekunle Abiona is a Senior Medical Professional with circa
                15 years experience in the health and medical space.
              </span>
            </div>
          </div>
          <div className='formImage'>
            <img
              className='appointmentform'
              src={calendly}
              alt='appointmentform'
            />
          </div>
        </div>
      </main>

      <div className='footer'>
        <Footer>
          {footerChildren.map(({ id, item }) => {
            return (
              <Link key={id} className='footer__btn'>
                <span className='about_span'>{item}</span>
              </Link>
            )
          })}
        </Footer>
      </div>
    </section>
  )
}

export default AppointmentPage

const footerChildren = [
  {
    id: 1,
    item: 'Support',
  },
  {
    id: 2,
    item: 'Privacy policy',
  },
  {
    id: 3,
    item: 'Terms of service',
  },
]
