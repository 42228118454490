import Footer from 'components/footer/footer';
import Navbar from 'components/nav/nav';
import React from 'react';

const ContactUs = () => {
    return (
        <div>
            <Navbar />
            <Footer item1='Contact' item2='Privacy Policy' item3='Terms of service' />
        </div>
    );
};

export default ContactUs;