export const getBoxSize = (width, height, params) => {
  let { x_max_size, y_max_size, y_min_size, x_min_size } = params

  let larger_dim = 'x'
  let larger_curr_value = null
  let larger_dim_value = null

  let x_check = parseFloat(width) - parseFloat(x_max_size)
  let y_check = parseFloat(height) - parseFloat(y_max_size)

  if (x_check < y_check) {
    larger_dim = 'y'
    larger_curr_value = parseFloat(height)
    larger_dim_value = parseFloat(y_max_size)
  } else {
    larger_dim = 'x'
    larger_curr_value = parseFloat(width)
    larger_dim_value = parseFloat(x_max_size)
  }

  let tn_width = width
  let tn_height = height

  const target_size = parseInt(larger_dim_value)
  if (larger_curr_value > larger_dim_value) {
    if (larger_dim === 'x') {
      tn_width = Math.floor(target_size)
      tn_height = Math.floor((height / width) * target_size)
    } else {
      tn_height = Math.floor(target_size)
      tn_width = Math.floor((width / height) * target_size)
    }
  } else {
    tn_width = width
    tn_height = height
  }

  if (y_min_size && x_min_size) {
    if (tn_height < y_min_size) {
      tn_height = Math.floor(y_min_size)
      tn_width = Math.floor((width / height) * y_min_size)
    }

    if (tn_width < x_min_size) {
      tn_width = Math.floor(x_min_size)
      tn_height = Math.floor((height / width) * x_min_size)
    }
  }
  return {
    width: tn_width,
    height: tn_height,
  }
}
