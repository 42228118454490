import React from 'react'
import { Link } from 'react-router-dom'
import Footer from 'components/footer/footer'
import Header from 'components/header/header'
import Navbar from 'components/nav/nav'
import Main from './main'
import './homepage.scss'

const Homepage = () => {
  return (
    <section className='section'>
      <Navbar />
      <div className='emptywrapper'>
        <div className='wrapper__inner__one'></div>
        <div className='wrapper__inner_two'>
          <span className='frame'></span>
        </div>
      </div>
      <Header />
      <Main />

      <Footer>
        {footerChildren.map(({ id, item }) => {
          return (
            <Link key={id} className='footer__btn'>
              <span className='about_span'>{item}</span>
            </Link>
          )
        })}
      </Footer>
    </section>
  )
}

export default Homepage

const footerChildren = [
  {
    id: 1,
    item: 'Contact',
  },
  {
    id: 2,
    item: 'Privacy policy',
  },
  {
    id: 3,
    item: 'Terms of service',
  },
]
