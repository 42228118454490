import React, { useState } from 'react'
import './modal.scss'

const Modal = ({ closeModal }) => {
  const [counsellorForm, setCounsellorForm] = useState({
    name: '',
    profession: '',
    linkedinProfile: '',
    email: '',
    phoneNo: '',
  })

  const inputHandler = (e) => {
    const { name, value } = e.target
    setCounsellorForm({
      ...counsellorForm,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className='modalBackground' onClick={() => closeModal(false)}>
      <div className='modalcontainer' onClick={(e) => e.stopPropagation()}>
        <div className='modaldescriptions'>
          <span className='modalheading'>Join us as a Counsellor</span>
          <span className='modalheadingdesc'>
            Une question ? Oubliez Google. Nos professionnels de santé sont
            disponibles sur votre app Alan, même le weekend.
          </span>
        </div>
        <form className='modalform' onSubmit={handleSubmit}>
          <div className='inputgroup'>
            <label htmlFor='' className='inputlabel'>
              Name
            </label>
            <input
              type='text'
              className='inputfield'
              value={counsellorForm.name}
              name='name'
              onChange={inputHandler}
            />
          </div>
          <div className='inputgroup'>
            <label htmlFor='' className='inputlabel'>
              Profession
            </label>
            <input
              type='text'
              className='inputfield'
              value={counsellorForm.profession}
              name='profession'
              onChange={inputHandler}
            />
          </div>
          <div className='inputgroup'>
            <label htmlFor='' className='inputlabel'>
              LinkedIn Profile
            </label>
            <input
              type='text'
              className='inputfield'
              value={counsellorForm.linkedinProfile}
              name='linkedinProfile'
              onChange={inputHandler}
            />
          </div>
          <div className='inputgroup'>
            <label htmlFor='' className='inputlabel'>
              Email
            </label>
            <input
              type='text'
              className='inputfield'
              value={counsellorForm.email}
              name='email'
              onChange={inputHandler}
            />
          </div>
          <div className='inputgroup'>
            <label htmlFor='' className='inputlabel'>
              Phone no
            </label>
            <input
              type='text'
              className='inputfield'
              value={counsellorForm.phoneNo}
              name='phoneNo'
              onChange={inputHandler}
            />
          </div>

          <button className='modalbtn'>Submit request</button>
        </form>
      </div>
    </div>
  )
}

export default Modal
