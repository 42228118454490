import React, { useState, useEffect } from 'react'
import './main.scss'
import man from 'assets/images/twoladies.png'
import Accordion from 'components/accordion/accordion'
import Modal from 'components/modal/modal'

const accordion = [
  {
    id: 0,
    title: 'How do I join as Counsellor?',
    child: [
      'Click on the Join Button',
      'Fill and Submit the Counsellor form',
      'Wait for our feedback on your interest',
      'If selected, You will get a email notification',
      'Set up your account',
    ],
  },
  {
    id: 1,
    title: 'What’s the approval criteria to be a Counsellor?',
    child: [
      'At Helpspace, we vet through every single application and that can take up to a week sometimes to ensure we have a quality and passionate  Therapist/life coach.',
    ],
  },
  {
    id: 2,
    title: 'Our criteria for approval are:',
    child: [
      'Relevant experience in the area of interest ( > 10 years)',
      'Must be a licenced therapist or Certified Life Coach',
      'Has experience in coaching and counselling people dealing with mental health issues.',
      'Must be tech savvy.',
    ],
  },
  {
    id: 3,
    title: 'Will I be paid for Counselling?',
    child: [
      'We are an NGO platform , We don’t pay Counsellor, we don’t charge potential users.',
    ],
  },
]

const steps = [
  {
    id: 1,
    heading: 'step',
    stepNum: 1,
    title: 'Quick access to Therapist',
    content:
      ' No sign up is required. We care more about your mental health than your data',
  },
  {
    id: 2,
    heading: 'step',
    stepNum: 2,
    title: 'Get paired with a specialist',
    content: 'We connect you to specialists in the field or area you need help',
  },
  {
    id: 3,
    heading: 'step',
    stepNum: 3,
    title: 'Decide who to speak with',
    content: 'Pick a preferred coach or therapist you will like to speak with',
  },
  {
    id: 4,
    heading: 'step',
    stepNum: 4,
    title: 'Book a session',
    content: 'Pick a preferred coach or therapist you will like to speak with',
  },
]

const checkIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_54_1405)'>
      <path
        d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
        fill='#5B9DFF'
      />
      <path
        d='M11 18.5L14.5 22L21.5 10'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_54_1405'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
const Main = () => {
  const [modal, setModal] = useState(false)
  const [openIndex, setOpenIndex] = useState(null)

  const modalState = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [modal])

  return (
    <main className='main'>
      <div className='layers'>
        <div className='layer__one'>
          <div className='layer__child'>
            {checkIcon}
            <span className='desc'>Mental health coaching</span>
          </div>
          <div className='layer__child'>
            {checkIcon}
            <span className='desc'>Career advice</span>
          </div>
          <div className='layer__child'>
            {checkIcon}
            <span className='desc'>Relationship counselling</span>
          </div>
        </div>
        <div className='layer__two'>
          <div className='layer__child'>
            {checkIcon}
            <span className='desc'>Educational counselling</span>
          </div>
          <div className='layer__child'>
            {checkIcon}
            <span className='desc'>All for free</span>
          </div>
        </div>
      </div>

      <div className='image__steps'>
        <div className='stepslayer'>
          <div className='steps__heading'>
            <div className='heading__box'>
              <p className='heading__title'>
                Are feeling sad, depressed, anxious, stressed? <br /> Be at a
                better mind space with helpspace
              </p>
            </div>
            <div className='hidden__boxes'>
              <div className='hidden'></div>
              <div className='hidden'></div>
              <div className='hidden'></div>
            </div>
          </div>
        </div>

        <div className='stepsContainer'>
          <div className='image__box'>
            <div className='mainvector'></div>
            <img src={man} alt='' loading='lazy' />
          </div>
          <div className='steps'>
            {steps.map(({ id, stepNum, ...rest }) => {
              return (
                <div className='steps__child' key={id}>
                  <div className='single__step'>
                    <div className='top'>
                      <span className='span'>{rest.heading}</span>
                      <span className='stepNumber'>{stepNum}</span>
                    </div>
                    <div className='bottom'>
                      <span className='title'>{rest.title}</span>
                      <span className='content'>{rest.content}</span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className='join__container'>
        <div className='join__box'>
          <div className='join__top'>
            <span className='join__span'>
              <p className='join__text'>Join us as a Counsellor</p>
            </span>
            <span className='join__question'>
              <p className='question__text'>
                Une question ? Oubliez Google. Nos professionnels de santé sont
                disponibles sur votre app Alan, même le weekend.
              </p>
            </span>
            <div className='sendApplication'>
              <button className='sendbtn' onClick={modalState}>
                Send application
              </button>
            </div>
          </div>
          <div className='join__bottom'>
            <div className='accordionWrapper'>
              {accordion.map(({ id, title, child }) => {
                return (
                  <Accordion
                    key={id}
                    index={id}
                    title={title}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                    carouselContent={child.map((a, index) => {
                      return <li key={index}>{a}</li>
                    })}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {modal ? <Modal closeModal={setModal} /> : ''}
    </main>
  )
}

export default Main
