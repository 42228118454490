import React, { useState, useRef, useEffect } from 'react'
import './accordion.scss'

const assets = {
  rightChevron: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.79279 7.29296C5.98031 7.10549 6.23462 7.00017 6.49979 7.00017C6.76495 7.00017 7.01926 7.10549 7.20679 7.29296L10.4998 10.586L13.7928 7.29296C13.885 7.19745 13.9954 7.12127 14.1174 7.06886C14.2394 7.01645 14.3706 6.98886 14.5034 6.98771C14.6362 6.98655 14.7678 7.01186 14.8907 7.06214C15.0136 7.11242 15.1253 7.18667 15.2192 7.28056C15.3131 7.37446 15.3873 7.48611 15.4376 7.60901C15.4879 7.7319 15.5132 7.86358 15.512 7.99636C15.5109 8.12914 15.4833 8.26036 15.4309 8.38236C15.3785 8.50437 15.3023 8.61471 15.2068 8.70696L11.2068 12.707C11.0193 12.8944 10.765 12.9997 10.4998 12.9997C10.2346 12.9997 9.98031 12.8944 9.79279 12.707L5.79279 8.70696C5.60532 8.51943 5.5 8.26512 5.5 7.99996C5.5 7.73479 5.60532 7.48049 5.79279 7.29296Z'
        fill='#9CA3AF'
      />
    </svg>
  ),
  downChevron: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.79279 14.707C7.60532 14.5195 7.5 14.2652 7.5 14C7.5 13.7349 7.60532 13.4806 7.79279 13.293L11.0858 10L7.79279 6.70704C7.61063 6.51844 7.50983 6.26584 7.51211 6.00364C7.51439 5.74144 7.61956 5.49063 7.80497 5.30522C7.99038 5.11981 8.24119 5.01465 8.50339 5.01237C8.76558 5.01009 9.01818 5.11088 9.20679 5.29304L13.2068 9.29304C13.3943 9.48057 13.4996 9.73488 13.4996 10C13.4996 10.2652 13.3943 10.5195 13.2068 10.707L9.20679 14.707C9.01926 14.8945 8.76495 14.9998 8.49979 14.9998C8.23462 14.9998 7.98031 14.8945 7.79279 14.707Z'
        fill='#9CA3AF'
      />
    </svg>
  ),
}

const { rightChevron, downChevron } = assets

const Accordion = ({
  title,
  content,
  index,
  openIndex,
  setOpenIndex,
  carouselContent,
}) => {
  const [carouselHeight, setCarouselHeight] = useState(0)
  const isOpen = index === openIndex

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index)
  }

  const carouselContentRef = useRef(null)

  useEffect(() => {
    setCarouselHeight(isOpen ? carouselContentRef.current.scrollHeight : 0)
  }, [isOpen])

  return (
    <div className='accordion'>
      <div onClick={toggleAccordion} className='accordion__child'>
        {isOpen ? rightChevron : downChevron}
        <span className='accordion__title'>{title}</span>
      </div>
      <div className='accordion__listContainer'>
        <div
          className='listcontainer'
          style={{ height: `${carouselHeight}px` }}
          ref={carouselContentRef}
          key={index}
        >
          {isOpen ? <span className='itemlists'>{carouselContent}</span> : null}
        </div>
      </div>
    </div>
  )
}

export default Accordion
