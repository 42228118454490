import SearchCoach from 'pages/searchCoarch/searchCoach'
import ContactUs from 'pages/contactUs/contactUs'
import Homepage from 'pages/homepage/homepage'
import { Routes, Route } from 'react-router-dom'
import './default.scss'
import AppointmentPage from 'pages/appointment/appointment'

const App = () => {
  return (
    <div className='App'>
      <Routes>
        <Route>
          <Route index element={<Homepage />} />
          <Route path='/search-coach' element={<SearchCoach />} />
          <Route path='/appointment' element={<AppointmentPage />} />
          <Route path='/contact-us' element={<ContactUs />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
